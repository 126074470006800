/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import logo from "../../img/logo-app.png";
import "./util/index.css";
import { Container, Button, Link } from "react-floating-action-button";
import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { ADD_DATA, QUERY_DATA, QUERY_TYPE } from "./apollo";
import { loadingData, messageError, messageSuccess, valiDate } from "../../helper";
import useReactRouter from "use-react-router";
import Answer from "./Aswer";
export default function FromRegister() {
  const { match } = useReactRouter();
  const [listDataType, setDataType] = useState();
  const getId = match?.params?._id;
  const [addData, { loading }] = useMutation(ADD_DATA);
  const [fetchData, { data: setData, loading: loadingFetch }] = useLazyQuery(QUERY_DATA, {
    fetchPolicy: "cache-and-network",
  });
  const [fetchDataType, { data: setType, loadingDataType }] = useLazyQuery(
    QUERY_TYPE,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    fetchData({
      variables: {
        where: {
          examType: getId,
          status: 1,
        },
      },
    });
  }, [getId]);

  useEffect(() => {
    if(!getId){
      return
    }
    fetchDataType({
      variables: {
        where: {
          _id: parseInt(getId),
        },
        limit: 1,
      },
    });
  }, [getId]);
  useEffect(() => {
    setDataType(setType?.examTypes?.data[0]);
  }, [setType]);
  const _results = setData?.questions?.data || [];
  const _initialValues = {};
  for (const _data of _results) {
    const name = "answer-" + _data?._id;
    _initialValues[name] = "";
  }

  const { handleChange, values, errors, handleSubmit, resetForm } = useFormik({
    initialValues: {
      ..._initialValues,
      phoneNumber: "",
      username: "",
    },
    validate: (values) => {
      const errors = {};
      for (const key in _initialValues) {
        if (!values[key]) {
          errors[key] = "ກະລູນາເລືອກຄຳຕອບກ່ອນ❗";
        }
      }
      if (!values.phoneNumber) {
        errors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ❗";
      }
      if (!values.username) {
        errors.username = "ກະລຸນາປ້ອນຊື່❗";
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        const newValues = {
          ...values,
        };
        delete newValues.username;
        delete newValues.phoneNumber;

        for (const key in newValues) {
          const _values = {
            username: values?.username,
            phoneNumber: parseInt(values?.phoneNumber),
            questionID: key.split("answer-")[1],
            answerID: newValues[key],
          };
          await addData({
            variables: {
              data: _values,
            },
          });
        }
        messageSuccess("ບັນທືກສຳເລັດ");
        setTimeout((e) => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        console.log(error);
        messageError("ບັນທືກຂໍ້ມູນຜິດພາດ");
      }
    },
  });
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="card mt-1" style={{ borderTop: "5px solid red" }}>
            <div className=" mt-2 text-center">
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                style={{ height: 60 }}
              />
            </div>
            <hr style={{ border: "dashed 0.1px #fafafafa" }}></hr>
            <div className="card-body">
              <center>
                {listDataType && (
                  <>
                    <i className="fa-solid fa-circle me-2" />
                    <b className="fs-4 mb-5" style={{ color: "black" }}>
                      {listDataType?.title ? listDataType?.title : ""}
                    </b>
                    <br />
                  </>
                )}
              </center>
              <b style={{ color: "black" }}>
                * ຈົ່ງຕື່ມຂໍ້ມູນສ່ວນຕົວ ແລະ
                ໃສ່ຄໍາຕອບທີ່ຖືກຕ້ອງກັບຄໍາຖາມດ້ານລຸ່ມນີ້ໃຫ້ຄົບທຸກຂໍ້.
              </b>
              {/* <div  className="text-center border-top w-50 bg-success" /> */}
              <div className="form-group mt-4">
                <FormControl fullWidth sx={{ m: 0 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    ຊື່ ແລະ ນາມນາມສະກຸນ{valiDate()}
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <i className="fas fa-user me-2" />
                      </InputAdornment>
                    }
                    value={values.username}
                    type="text"
                    onChange={handleChange}
                    label="ຊື່ ແລະ ນາມນາມສະກຸນ"
                    name="username"
                    placeholder="ຊື່ ແລະ ນາມນາມສະກຸນ"
                    error={errors.username}
                    sx={{
                      m: 0,
                      width: "100%",
                      height: "55px",
                      backgroundColor: "white",
                    }}
                    inputProps={{
                      maxLength: 8,
                    }}
                  />
                </FormControl>
                <div className="text-danger mt-2">{errors.username}</div>
              </div>
              <div className="form-group mt-3">
                <FormControl fullWidth sx={{ m: 0 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    ເບີໂທ{valiDate()}
                  </InputLabel>
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">+85620</InputAdornment>
                    }
                    value={values.phoneNumber}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      const value = e.target.value;
                      if (value === "" || re.test(value)) {
                        handleChange(e);
                      }
                    }}
                    label="ເບີໂທ"
                    name="phoneNumber"
                    placeholder="..."
                    error={errors.phoneNumber}
                    sx={{
                      m: 0,
                      width: "100%",
                      height: "55px",
                      backgroundColor: "white",
                    }}
                    inputProps={{
                      maxLength: 8,
                    }}
                  />
                </FormControl>
                <div className="text-danger mt-2">{errors.phoneNumber}</div>
              </div>
            </div>
          </div>
        </div>
            {setData?.questions?.data?.map((data, index) => (
              <div className="row">
                <div
                  className="card mt-1"
                  style={{
                    border: errors[`answer-${data?._id}`]
                      ? "1px solid red"
                      : "",
                    borderTop: "4px solid black",
                  }}
                >
                  <div className="card-body">
                    <div className="form-group">
                    {loadingFetch ? (loadingData(25,"ກຳລັງໂຫຼດຂໍ້ມູນ...")):"" }
                      <h3>
                        {" "}
                        {index + 1}. {data?.title}
                      </h3>
                      <Answer
                        getId={data?._id}
                        parentIndex={data?._id}
                        onChange={handleChange}
                        errors={errors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}

        <div className="row">
          <div className="card mt-1 mb-3">
            <div className="card-body">
              <button
                className="btn btn-primary btn-block btn-lg rounded"
                type="button"
                onClick={handleSubmit}
              >
                <i className="fa-solid fa-paper-plane me-2" />
                ຢືນຢ‍ັນສົ່ງ
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
