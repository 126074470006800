import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FromRegister from "../pages/formRegister/form";
export default function Routes() {
  return (
    <Router>
      <html style={{ backgroundColor: "#ccc"}}>
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => <FromRegister {...props} />}
          />
          <Route
            exact
            path="/:_id"
            component={(props) => <FromRegister {...props} />}
          />
        </Switch>
      </html>
    </Router>
  );
}
