
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import logo from "../../img/logo-app.png";
import "./util/index.css";
import { Container, Button, Link } from "react-floating-action-button";
import {
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import {  QUERY_ANSWER } from "./apollo";
import { v4 as uuidv4 } from "uuid";
export default function Answer({ getId, onChange, parentIndex, errors }) {
  const [fetchData, { data: setData, loadingData }] = useLazyQuery(
    QUERY_ANSWER,
    { fetchPolicy: "cache-and-network" }
  );
  useEffect(() => {
    fetchData({
      variables: {
        where: {
          questionID: getId,
        },
      },
    });
  }, [getId]);
  return (
    <div
      className="justify-content-md-center p-1"
      style={{ paddingBottom: 100, marginTop: 10 }}
    >
      <div className="container ">
        {setData &&
          setData?.answers?.data?.map((data, index) => (
            <div className="input-group">
              <div className="form-check" key={index}>
                <input
                  className="form-check-input"
                  type="radio"
                  value={`${data?._id}`}
                  onChange={(e) => {
                    if (onChange) {
                      onChange(e);
                    }
                  }}
                  name={`answer-${parentIndex}`}
                  id={`answer-${parentIndex}${index}`}
                />
                <label
                  className="form-check-label mt-1"
                  htmlFor={`answer-${parentIndex}${index}`}
                >
                  {data?.title ? data?.title : "-"}
                </label>
              </div>
            </div>
          ))}
        <div className="text-danger mt-1">
          {errors[`answer-${parentIndex}`]}
        </div>
      </div>
    </div>
  );
}
