import { gql } from "@apollo/client";
export const ADD_DATA = gql`
  mutation CreateExamResult($data: ExamResultInput!) {
    createExamResult(data: $data) {
      _id
    }
  }
`;

export const QUERY_DATA = gql`
  query Questions(
    $limit: Int
    $skip: Int
    $orderBy: OrderByInput
    $where: QuestionWhereInput
  ) {
    questions(limit: $limit, skip: $skip, orderBy: $orderBy, where: $where) {
      data {
        _id
        title
        examType {
          _id
          title
        }
      }
    }
  }
`;

export const QUERY_ANSWER = gql`
  query Answers($where: AnswerWhereInput) {
    answers(where: $where) {
      data {
        _id
        title
      }
    }
  }
`;

export const QUERY_TYPE = gql`
  query ExamTypes($where: ExamTypeWhereInput) {
    examTypes(where: $where) {
      data {
        _id
        title
      }
    }
  }
`;
